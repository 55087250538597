@import '../../breakpoints.sass';

.topHero {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-height: 30em;
	direction: ltr;
	background-position: center;

	.text-wrap {
		margin-top: auto;
		width: 100%;

		h1 {
			text-shadow: 2px 2px 8px black;
			text-transform: uppercase;
			color: white;
			word-wrap: break-word;
			font-size: 40px; } } }

@media (min-width: $bp-large ) {
	.topHero {
		min-height: 35em;
		.text-wrap {
			h1 {
				font-size: 60px; } } } }

@media (min-width: $bp-x-large ) {
	.topHero {
		min-height: 92vh; } }

@media (max-width: $bp-x-small) {
	.topHero {
		min-height: unset;
		.text-wrap {
			h1 {
				margin: 20px 30px;
				font-size: x-large; } } } }
