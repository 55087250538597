@import "./colors.sass";
@import url('https://fonts.googleapis.com/css?family=Lato:100,400,700&display=swap');

.App {
	font-family: 'Lato';
	color: $darkSlate; }

h2 {
	font-size: 30px; }

.paragraph {
	margin-top: 40px;
	color: $darkSlate;
	font-size: 18px;
	text-align: justify;
	line-height: 25px; }

.darkMode p {
	color: white; }
