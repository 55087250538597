@import "../../colors.sass";
@import "../../breakpoints.sass";

.sectionWrapper {
	position: relative;
	width: 100%;
	display: block;
	overflow: auto;
	padding-top: 60px;
	padding-bottom: 60px;

	.headingWrapper {
		.sectionHeading {
			margin-top: 0;
			font-size: 60px;
			margin-bottom: 0;
			word-wrap: break-word; }

		.sectionSubheading {
			margin-top: 0;
			font-size: 20px;
			word-wrap: break-word; } }

	p {
		line-height: 22px;
		text-align: left; } }

@media (max-width: $bp-x-small) {
	.sectionWrapper {
		.headingWrapper {
			text-align: left !important;
			.sectionHeading {
				font-size: xx-large; } } } }
