@import url(https://fonts.googleapis.com/css?family=Lato:100,400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App{font-family:'Lato';color:#5a5e65}h2{font-size:30px}.paragraph{margin-top:40px;color:#5a5e65;font-size:18px;text-align:justify;line-height:25px}.darkMode p{color:white}

.topHero{width:100%;display:flex;align-items:flex-end;justify-content:center;min-height:30em;direction:ltr;background-position:center}.topHero .text-wrap{margin-top:auto;width:100%}.topHero .text-wrap h1{text-shadow:2px 2px 8px black;text-transform:uppercase;color:white;word-wrap:break-word;font-size:40px}@media (min-width: 1200px){.topHero{min-height:35em}.topHero .text-wrap h1{font-size:60px}}@media (min-width: 1500px){.topHero{min-height:92vh}}@media (max-width: 576px){.topHero{min-height:unset}.topHero .text-wrap h1{margin:20px 30px;font-size:x-large}}

.mobileNav{width:100%}

.anchorBar{display:flex;z-index:1;flex-direction:row;direction:rtl;align-items:center;width:100%;padding:10px 0 10px 10px;transition:padding 0.2s ease-in-out;background-color:#ffc843}.anchorBar.sticky{padding:0 0 0 10px;box-shadow:0px -10px 20px 5px rgba(0,0,0,0.8)}.anchorBar .anchorList{min-width:50%;display:flex;flex-direction:row;flex-wrap:wrap-reverse;justify-content:space-evenly;align-items:center;list-style-type:none;font-size:medium}.anchorBar .anchorList .anchorItem{font-weight:700;color:#5a5e65}.anchorBar .anchorList .anchorItem a{text-decoration:none;color:#5a5e65}.anchorBar .anchorList .slash{color:white;font-size:x-large;font-weight:400}@media (max-width: 1920px){.anchorBar .anchorList{min-width:70%}}@media (max-width: 1500px){.anchorBar .anchorList{min-width:80%}}@media (max-width: 1200px){.anchorBar .anchorList{min-width:100%}}@media (max-width: 768px){.anchorBar{padding:15px 35px}.anchorBar.sticky{padding:10px 35px}.anchorBar .anchorList{min-width:100%;padding:0 15px;flex-direction:column-reverse;align-items:flex-start;font-size:large;line-height:45px;justify-content:unset}.anchorBar .anchorList .anchorItem{padding-top:3px;padding-bottom:3px}.anchorBar .anchorList .anchorItem .slash{font-size:xx-large;font-weight:bold}}

.sectionWrapper{position:relative;width:100%;display:block;overflow:auto;padding-top:60px;padding-bottom:60px}.sectionWrapper .headingWrapper .sectionHeading{margin-top:0;font-size:60px;margin-bottom:0;word-wrap:break-word}.sectionWrapper .headingWrapper .sectionSubheading{margin-top:0;font-size:20px;word-wrap:break-word}.sectionWrapper p{line-height:22px;text-align:left}@media (max-width: 576px){.sectionWrapper .headingWrapper{text-align:left !important}.sectionWrapper .headingWrapper .sectionHeading{font-size:xx-large}}

.footer{height:250px;background-color:white;display:flex;align-items:center;justify-content:center;box-shadow:inset 0 20px 20px -20px rgba(0,0,0,0.8)}

