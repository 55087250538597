@import "../../colors.sass";
@import "../../breakpoints.sass";

.anchorBar {
	display: flex;
	z-index: 1;
	flex-direction: row;
	direction: rtl;
	align-items: center;
	width: 100%;
	padding: 10px 0 10px 10px;
	transition: padding 0.2s ease-in-out;
	background-color: $yellow;
	&.sticky {
		padding: 0 0 0 10px;
		box-shadow: 0px -10px 20px 5px rgba(0, 0, 0, 0.8); }

	.anchorList {
		min-width: 50%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap-reverse;
		justify-content: space-evenly;
		align-items: center;
		list-style-type: none;
		font-size: medium;

		.anchorItem {
			font-weight: 700;
			color: $darkSlate;
			a {
				text-decoration: none;
				color: $darkSlate; } }

		.slash {
			color: white;
			font-size: x-large;
			font-weight: 400; } } }

@media (max-width: $bp-monster) {
	.anchorBar {
		.anchorList {
			min-width: 70%; } } }

@media (max-width: $bp-x-large) {
	.anchorBar {
		.anchorList {
			min-width: 80%; } } }

@media (max-width: $bp-large) {
	.anchorBar {
		.anchorList {
			min-width: 100%; } } }

@media (max-width: $bp-small) {
	.anchorBar {
		padding: 15px 35px;
		&.sticky {
			padding: 10px 35px; }
		.anchorList {
			min-width: 100%;
			padding: 0 15px;
			flex-direction: column-reverse;
			align-items: flex-start;
			font-size: large;
			line-height: 45px;
			justify-content: unset;
			.anchorItem {
				padding-top: 3px;
				padding-bottom: 3px;
				.slash {
					font-size: xx-large;
					font-weight: bold; } } } } }
